import { NgModule } from "@angular/core";

// app
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./features/shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Auth } from "aws-amplify";
import { AuthInterceptor } from "./auth.interceptor";
import { environment } from "@mypxplat/xplat/core";

Auth.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: environment.cognito.userPoolId,
    userPoolWebClientId: environment.cognito.clientId,
  },
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

declare global {
  interface Window {
    analytics: any;
  }
}

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
